export enum AVAILABLE_LANGUAGES {
    en = 'en',
    ar = 'ar',
}
export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.ar;

// language & localization

export type StringValues<T> = {
    [K in keyof T]: T[K] extends string ? T[K] : never;
}[keyof T];
export const RETAIL_BACKEND = `${process.env.NEXT_PUBLIC_RETAIL_BACKEND}/v1/api`;
export const NEST_RETAIL_BACKEND = `${process.env.NEXT_PUBLIC_RETAIL_BACKEND}/api`;
export const SERVER_FETCH_ORIGIN =
    process.env.NEXT_PUBLIC_SERVER_FETCH_ORIGIN || 'sylndr.app';
export const COMMON_SERVER_HEADERS = {
    origin: SERVER_FETCH_ORIGIN,
    Connection: 'keep-alive',
};

export const ACQUISITION_BACKEND =
    process.env.NEXT_PUBLIC_ACQUISITION_BACKEND ||
    'https://acquisition-dev.sylndr.app/api';
export const ACQUISITION_BACKEND_OLD =
    process.env.NEXT_PUBLIC_ACQUISITION_BACKEND_OLD ||
    'https://sylndrwebsite-api-dev.sylndr.app/api';
export const APP_ENV = process.env.NEXT_PUBLIC_ENV;

export const BASE_URL =
    process.env.NEXT_PUBLIC_BASE_URL ||
    'https://sylndrwebsite-api-dev.sylndr.app/api';

export const SPECIFIC_CONTACT_EXPERIMENT =
    process.env.NEXT_PUBLIC_SPECIFIC_CONTACT_EXPERIMENT || '';

export const NEXT_APP_ACQUISITION_EXPERIMENT_OTHER_INFO =
    process.env.NEXT_APP_ACQUISITION_EXPERIMENT_OTHER_INFO || '';

export const NEXT_APP_AQUISITION_DIFFERENTIATION_EXPERIMENT =
    process.env.NEXT_APP_AQUISITION_DIFFERENTIATION_EXPERIMENT || '';

export const NEXT_PUBLIC_VERCEL_URL =
    process.env.NEXT_PUBLIC_VERCEL_URL || 'sylndr.com';

export const NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY =
    process.env.NEXT_APP_SYLNDR_GOOGLE_MAP_API_KEY ||
    'AIzaSyCq6pulule7C_uVVK4xcJRAcNbLERXXsKo';

const NEXT_APP_VERSION_HASH = process.env.NEXT_APP_VERSION_HASH;
export const NEXT_PUBLIC_ASSETS_PREFIX_BASE =
    process.env.NEXT_PUBLIC_ASSETS_PREFIX_BASE;
export const NEXT_APP_ASSETS_PREFIX = `${NEXT_PUBLIC_ASSETS_PREFIX_BASE}/c-${NEXT_APP_VERSION_HASH}`;
export const NEXT_APP_SENTRY_DSN =
    process.env.NEXT_APP_SENTRY_DSN ||
    'https://f030b4a337ac9f25697f9fc5be10b865@o4505683830112256.ingest.sentry.io/4505683832406016';
export const NEXT_APP_SENTRY_ENV = process.env.NEXT_APP_SENTRY_ENV || 'dev';

export const NEXT_PUBLIC_GOOGLE_OPTIMIZE_API_KEY =
    process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_API_KEY || 'OPT-TWW67ZL';

export const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_API_KEY =
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_API_KEY || '';

export const NEXT_PUBLIC_WEB_ENAGE_API_KEY =
    process.env.NEXT_PUBLIC_WEB_ENAGE_API_KEY || '';

export const NEXT_APP_SERVER_FETCH_CACHE_ENABLED =
    process.env.NEXT_APP_SERVER_FETCH_CACHE_ENABLED === 'true';
export const API_FETCH_LIB = JSON.stringify('axios');

export const UUID_V4_REGEX =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const CAR_LISTING_VALIDATION_TAG = 'INVALIDATE_VEHICLE_CACHE';
export const IMPERFECTION_VALIDATION_TAG = 'INVALIDATE_IMPERFECTION_CACHE';

export const All_CACHE_TAGS = [
    CAR_LISTING_VALIDATION_TAG,
    IMPERFECTION_VALIDATION_TAG,
];
export const SHORT_URL_BACKEND = process.env
    .SHORT_URL_BACKEND as unknown as string;

export const NEXT_PUBLIC_GROWTHBOOK_API_HOST = process.env
    .NEXT_PUBLIC_GROWTHBOOK_API_HOST as unknown as string;
export const NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY = process.env
    .NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY as unknown as string;
export const FEATURE_FLAG_MAP_REVAMP_A_B = 'map-revamp';

export const FEATURE_FLAG_BOOKING_SLOT_EXPANDTION = 'booking-slot-expantion';

export const GA4_MEASUREMENT_ID =
    APP_ENV != 'production' ? 'C7DPQ3R27V' : 'SH1G26JJ93';

export const NEXT_APP_FINANCE_API_INVALIDATION_MINUTES =
    (process.env
        .NEXT_APP_FINANCE_API_INVALIDATION_MINUTES as unknown as number) || 20;

export const NEXT_APP_INTERIOR_IMAGE_WIDTH = 6080;
export const NEXT_APP_INTERIOR_IMAGE_HEIGHT = 3040;

export const NEXT_APP_MAINTENANCE_DISABLE_BOOKING = false;

export const NEXT_APP_RETAIL_RESERVE_FEES = 10000;
export const NEXT_APP_RETAIL_WINCH_FEES = 800;
export const NEXT_APP_RETAIL_HOME_RESERVE_FEES = 10800;

export const SYLNDR_USER_SERVICE_BASE_URL = process.env
    .NEXT_PUBLIC_SYLNDR_USER_SERVICE_BASE_URL as unknown as string;
export const SYLNDR_OTP_SERVICE_BASE_URL = process.env
    .NEXT_PUBLIC_SYLNDR_OTP_SERVICE_BASE_URL as unknown as string;

export const FEATURE_FLAG_NEW_CONTACT =
    (process.env.NEXT_PUBLIC_FEATURE_FLAG_NEW_CONTACT as unknown as string) ===
    'true';
export const FEATURE_FLAG_DIGITALIZATION_RELEASE_3 =
    (process.env
        .NEXT_PUBLIC_FEATURE_FLAG_DIGITALIZATION_PHASE_3 as unknown as string) ===
    'true';
export const FEATURE_FLAG_SHOW_NEW_CAR_SUMMARY =
    (process.env
        .NEXT_PUBLIC_FEATURE_FLAG_SHOW_NEW_CAR_SUMMARY as unknown as string) ===
    'true';
export const FEATURE_FLAG_SHOW_SYLNDR_HUBS =
    (process.env
        .NEXT_PUBLIC_FEATURE_FLAG_SHOW_SYLNDR_HUBS as unknown as string) ===
    'true';

export const FEATURE_FLAG_WAREHOUSE_INSPECTION_SELECTION_NEW_PAGE =
    'warehouse-inspection-selection-newpage';

export const NEXT_PUBLIC_PAYMENT_IFRAME_DISAPPEAR_DURATION = parseInt(
    process.env
        .NEXT_PUBLIC_PAYMENT_IFRAME_DISAPPEAR_DURATION as unknown as string,
    10
);

export const NEXT_APP_ELASTIC_SEARCH_SEARCH_ENDPOINT =
    process.env.NEXT_APP_ELASTIC_SEARCH_SEARCH_ENDPOINT;

export const ELASTIC_SEARCH_PAGE_SIZE = 18;
export const CURRENT_URL_HEADER_NAME = 'x-s-url';
export const CURRENT_LANG_HEADER_NAME = 'x-s-lang';
export const EXTRA_BODY_CLASSES_HEADER_NAME = 'x-s-body-classes';
export const FEB_DISCOUNTS_S3_URL =
    process.env.NEXT_PUBLIC_FEB_DISCOUNTS_S3_URL;

export const RELEVANT_SORTING_S3_URL =
    process.env.NEXT_PUBLIC_RELEVANT_SORTING_S3_URL;

export const FEATURE_FLAG_BOOKING_LAST_DAY_STATUS =
    'FEATURE_FLAG_BOOKING_LAST_DAY_STATUS';
export const FEATURE_FLAG_DEADLINE_TO_SET = 2000;
