export * from './configs';
export * from './sylndr';
export * from './whatsIncluded';
export * from './checkout';

export const authTokenKey = 'userToken';
export const userIdKey = 'userId';

export const enum ServiceName {
    RETAIL = 'retail',
    ACQUISITION = 'acquisition',
    UNDEFINED = ''
}

export const retailFeesDynamicUpdateDate = '2024-01-22';
