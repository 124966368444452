'use client';
import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../constants';
import ar from './locales/ar.json';
import en from './locales/en.json';

declare module 'react-i18next' {
    interface CustomTypeOptions {
        defaultNS: typeof DEFAULT_LANGUAGE;
        resources: {
            en: typeof en;
            ar: typeof ar;
        };
    }
}
i18n.use(initReactI18next)
    .use(languageDetector)
    .init({
        fallbackLng: DEFAULT_LANGUAGE,

        resources: {
            en: { translation: en },
            ar: { translation: ar },
        },
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
        },
    });

i18n.languages = Object.values(AVAILABLE_LANGUAGES);
export default i18n;
