export const whatsIncludedContent = [
  {
    title: 'whatsIncluded.benefits.0.title',
    subtitle: 'whatsIncluded.benefits.0.subtitle',
    content: ['whatsIncluded.benefits.0.content.0', 'whatsIncluded.benefits.0.content.1'],
    price: 'registrationFees',
    expandedDefault: true,
  },
  {
    title: 'whatsIncluded.benefits.1.title',
    subtitle: 'whatsIncluded.benefits.1.subtitle',
    content: ['whatsIncluded.benefits.1.content.0', 'whatsIncluded.benefits.1.content.1'],
  },
  {
    title: 'whatsIncluded.benefits.2.title',
    subtitle: 'whatsIncluded.benefits.2.subtitle',
    content: ['whatsIncluded.benefits.2.content.0', 'whatsIncluded.benefits.2.content.1'],
  },
  { title: 'whatsIncluded.benefits.3.title' },
  {
    title: 'whatsIncluded.benefits.4.title',
  },
];
